import { Link, Stack, Typography } from "@mui/material";
import React from "react";
import { IconButtonAnimate } from "../../../components/animate";
import Image from "../../../components/image/Image";
import { useLocales } from "../../../locales";

// -------------------------------------------------------

// ------------ Styles ------

const langsHolderSx = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "end",
  p: { xs: "25px 28px 0px 28px", md: "25px 50px 0px 50px", lg: "52px 92px" },
  transform: { xs: "translateX(10 %)", md: "" },
};

const langsTextSx = {
  color: { xs: "#121212", md: "#fff" },
  fontWeight: 700,
  fontSize: "16px",
  cursor: "pointer",
  mr: "30px",
  LineHeight: "90px",
};

const flagHolderSx = {
  width: { xs: "30px", md: "55px" },
  height: { xs: "30px", md: "55px" },
  p: "0px",
};

export default function ChangeLanguageBox() {
  const { translate, currentLang, onChangeLang } = useLocales();

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
  };

  // ----------- JSX Code --------------
  return (
    <>
      {/* __________ Langauge Link and flag _________ */}
      <Stack sx={{ ...langsHolderSx }}>

        
        {/*---- flag  ---- */}
        <IconButtonAnimate
          sx={{ ...flagHolderSx }}
          onClick={() =>
            handleChangeLang(currentLang.value === "en" ? "ar" : "en")
          }
        >
          <Typography sx={{ ml: "10px", mr: "10px", cursor: "pointer" }}>
            {translate(currentLang.value)}
          </Typography>
          <img
            disabledEffect
            onClick={() =>
              handleChangeLang(currentLang.value === "en" ? "ar" : "en")
            }
            src={
              currentLang.value === "en"
                ? "/assets/icons/global2.svg"
                : "/assets/icons/global2.svg"
            }
            alt={currentLang.label}
            style={{ cursor: "pointer" }}
          />
        </IconButtonAnimate>

      </Stack>
    </>
  );
}
