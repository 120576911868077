import { createSlice } from "@reduxjs/toolkit";

import {
  getMealsService,
  createMealService,
  deleteMealService,
  getMealByIdService,
  updateMealService
} from "../services/meals_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingMeals: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  mealsList: [],
  metaData: null,
  mealInfo: null,
  isLoadingInfo: false,
};

const slice = createSlice({
  name: "meals",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET MEALS
    [getMealsService.fulfilled]: (state, action) => {
      state.isLoadingMeals = false;
      state.mealsList = action.payload?.data?.data?.data;
      state.metaData = action.payload?.data?.data?.meta;
    },
    [getMealsService.rejected]: (state, action) => {
      state.isLoadingMeals = false;
    },
    [getMealsService.pending]: (state) => {
      state.isLoadingMeals = true;
    },

    // ==> CREATE MEAL
    [createMealService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createMealService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createMealService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // ==> DELETE MEAL
    [deleteMealService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteMealService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteMealService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // GET =>  GET PACKAGE BY ID
    [getMealByIdService.fulfilled]: (state, action) => {
      state.isLoadingInfo = false;
      state.mealInfo = action.payload.data.data;
    },
    [getMealByIdService.rejected]: (state, action) => {
      state.isLoadingInfo = false;
    },
    [getMealByIdService.pending]: (state) => {
      state.isLoadingInfo = true;
    },

   // UPDATE =>  UPDATE MEAL
      [updateMealService.fulfilled]: (state, action) => {
        state.isLoadingCreate = false;
      },
      [updateMealService.rejected]: (state, action) => {
        state.isLoadingCreate = false;
      },
      [updateMealService.pending]: (state) => {
        state.isLoadingCreate = true;
      },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
