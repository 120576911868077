import { createSlice } from "@reduxjs/toolkit";

// SERVICE

import {
  getClientsService,
  getClientByIdService,
  createClientService,
  editClientService,
  deleteClientService,
  updateClientService
} from "../services/clients_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingCreate: false,
  isLoadingDelete: false,
  clientsList: [],
  meatClients: null,
  clientId: null,
  clientInfo: null,
};

const slice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
  },
  extraReducers: {
    // ==> GET CLIENTS
    [getClientsService.fulfilled]: (state, action) => {
      console.log(action.payload?.data?.data.data);
      state.isLoading = false;
      state.clientsList = action.payload?.data?.data?.data;
      state.meatClients = action.payload.data.data?.meta;
    },
    [getClientsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getClientsService.pending]: (state) => {
      state.isLoading = true;
    },

    // ==> GET CLIENT BY ID
    [getClientByIdService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.clientInfo = action.payload?.data?.data;
    },
    [getClientByIdService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getClientByIdService.pending]: (state) => {
      state.isLoading = true;
    },

    // POST =>  CREATE CLIENT
    [createClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // EDIT =>  EDIT CLIENT
    [editClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [editClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [editClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    // DELETE =>  DELETE CLIENT

    [deleteClientService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteClientService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteClientService.pending]: (state) => {
      state.isLoadingDelete = true;
    },

    // POST =>  CREATE CLIENT
    [createClientService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [editClientService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [editClientService.pending]: (state) => {
      state.isLoadingCreate = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setClientId } = slice.actions;
