import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET USERS
export const getUsersService = createAsyncThunk(
  "users/getUsersService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.usersURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET USER BY ID
export const getUserByIdService = createAsyncThunk(
  "users/getUserByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.usersURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// EDIT =>  EDIT USER
export const editUserService = createAsyncThunk(
  "users/editUserService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.usersURL}/${params?.id}`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE USER
export const createUserService = createAsyncThunk(
  "users/createUserService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.usersURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE USERS
export const deleteUserService = createAsyncThunk(
  "users/deleteUserService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.usersURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
