import { createSlice } from "@reduxjs/toolkit";

import { getPermissionService ,getRolesService,createRoleService,deleteRoleService } from "../services/roles_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingPermission: false,
  isLoadingRoles:false,
  isLoadingCreate:false,
  isLoadingDelete:false,
  permissionsList: [],
  rolesList:[]
};

const slice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET PERMISSIONS
    [getPermissionService.fulfilled]: (state, action) => {
      state.isLoadingPermission = false;
      state.permissionsList = action.payload?.data?.data;
    },
    [getPermissionService.rejected]: (state, action) => {
      state.isLoadingPermission = false;
    },
    [getPermissionService.pending]: (state) => {
      state.isLoadingPermission = true;
    },

    // ==> GET ROLES
    [getRolesService.fulfilled]: (state, action) => {
      state.isLoadingRoles = false;
      state.rolesList = action.payload?.data?.data;
    },
    [getRolesService.rejected]: (state, action) => {
      state.isLoadingRoles = false;
    },
    [getRolesService.pending]: (state) => {
      state.isLoadingRoles = true;
    },

    //CREATE ROLE 
    [createRoleService.fulfilled]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createRoleService.rejected]: (state, action) => {
      state.isLoadingCreate = false;
    },
    [createRoleService.pending]: (state) => {
      state.isLoadingCreate = true;
    },

    
    //Delete ROLE 
    [deleteRoleService.fulfilled]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteRoleService.rejected]: (state, action) => {
      state.isLoadingDelete = false;
    },
    [deleteRoleService.pending]: (state) => {
      state.isLoadingDelete = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
