import { createSlice } from "@reduxjs/toolkit";

// ----------------------------------------------------------------------

const initialState = {

    userPermissions: []
};

const slice = createSlice({
    name: "general",
    initialState,
    reducers: {

        setUserPermission: (state, action) => {
            state.userPermissions = action.payload;
        },
    },
    extraReducers: {

    },
});

// Reducer
export default slice.reducer;

// Actions
export const { setUserPermission } = slice.actions;
