import { createSlice } from "@reduxjs/toolkit";

import { getCategoriesService } from "../services/categories_service";

// ----------------------------------------------------------------------

const initialState = {
  isLoadingCategories: false,

  isLoadingMeals: false,

  categoriesList: [],

  mealsList: [],
};

const slice = createSlice({
  name: "categories",
  initialState,
  reducers: {},
  extraReducers: {
    // ==> GET Categories
    [getCategoriesService.fulfilled]: (state, action) => {
      state.isLoadingCategories = false;
      state.categoriesList = action.payload?.data?.data;
    },
    [getCategoriesService.rejected]: (state, action) => {
      state.isLoadingCategories = false;
    },
    [getCategoriesService.pending]: (state) => {
      state.isLoadingCategories = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {} = slice.actions;
