export const getCategoriesURL = "/dashboard/getCategories";

export const usersURL = "/dashboard/users";

export const getPermissionURL="/dashboard/getPermission"

export const rolesURL="/dashboard/roles"

export const mealsURL="/dashboard/meals"

export const packagesURL="/dashboard/packages"

export const updatePackagesURL ="/dashboard/updatePacakge"

export const kitchenURL="/dashboard/kitchen"

export const clientsURL="/dashboard/clients"