import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET MEALS
export const getMealsService = createAsyncThunk(
  "meals/getMealsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.mealsURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE MEAL
export const createMealService = createAsyncThunk(
  "meals/createMealService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.mealsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE MEAL
export const deleteMealService = createAsyncThunk(
  "meals/deleteMealService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.mealsURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET Meal BY ID
export const getMealByIdService = createAsyncThunk(
  "meals/getMealByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.mealsURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// UPDATE =>  UPDATE MEAL
export const updateMealService = createAsyncThunk(
  "meals/updateMealService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.mealsURL}/${params?.id}`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
