import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET PERNISSION
export const getPermissionService = createAsyncThunk(
  "roles/getPermissionService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.getPermissionURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// GET =>  GET ROLES
export const getRolesService = createAsyncThunk(
  "roles/getRolesService",
  async (thunkAPI) => {
    try {
      const response = await axios.get(URLS.rolesURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);


// POST =>  POST ROLE
export const createRoleService = createAsyncThunk(
  "roles/createRoleService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.rolesURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);



  // DELETE =>  DELETE ROLE
  export const deleteRoleService = createAsyncThunk(
    "roles/deleteRoleService",
    async (id,thunkAPI) => {
      try {
        const response = await axios.delete(`${URLS.rolesURL}/${id}`);
        return response;
      } catch (e) {
        return thunkAPI.rejectWithValue(e.response.data);
      }
    }
  );
  