// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  login: `Login`,
  // required
  email_is_required: `Email is required`,
  password_is_required: `Password is required`,
  email_must_be_a_valid_email_address: `Email must be a valid email address`,
  phone_number: `Phone number`,
  password: `Password`,
  enter_phone_number: `Enter phone number`,
  enter_password: `Enter password`,
  en: `English`,
  ar: `Arabic`,
  username: `username`,
  front_page: `Main Page`,
  banner: `Banner`,
  view_and_control: `View and control`,
  team: `Team`,
  workflow: `Workflow`,
  customers: `Customers`,
  packages: `Packages`,
  contact_us: `Contact Us`,
  meals: `Meals`,
  logs: `Logs`,
  logout: `Logout`,
  type: `Type`,
  name_ar: `Name in arabic`,
  name_en: `Name in english`,
  add_new_meal: `Create meal`,
  edit_meal: `Edit meal`,
  meal: `Meal`,
  enter_name_ar: `Enter name in arabic`,
  enter_name_en: `Enter name in english`,
  send: `Save`,
  categories: `Categories`,
  category: `category`,
  add_new_category: `Create category`,
  edit_category: `Edit category`,
  name: `Name`,
  enter_name: `Enter Name`,
  add_package: `create package`,
  edit_package: `Edit package`,
  package: `Package`,
  image: `Image`,
  restaurant_choice: `Restaurant choice`,
  enter_restaurant_choice: `Enter Restaurant choice`,
  max_carp: `Max carb`,
  enter_max_carp: `Enter max carb`,
  max_protin: `Max protein`,
  enter_max_protin: `Enter max protein`,
  salad_count: `Salad count`,
  snack_count: `Snack count`,
  price: `Price`,
  enter_price: `Enter price`,
  enter_snack_count: `Enter snack count`,
  enter_salad_count: `Enter salad count`,
  actions: `Actions`,
  search: `Search`,
  email: `Email`,
  is_active: `is Actice`,
  role: `Role`,
  add_new_user: `Create User`,
  password_confirmation: `Password Confirmation`,
  enter_email: `Enter Email`,
  delete: `Delete`,
  done: `Done !`,
  permissions: `Permissions`,
  roles: `Roles`,
  created_at: `Created At`,
  updated_at: `Update At`,
  id: `ID`,
  create_role: `Created Role`,
  add_new_role: `Create New Role`,
  category: `Category`,
  add_new_meal: ` Add New Meal`,
  quantity: `Quantity`,
  is_default: `Is Default ?`,
  edit_user: `Edit User`,
  edit: `Edit`,
  default_carp: `Default carb`,
  default_protin: `Default protein`,
  kitchen: `Kitchen`,
  total_carb: `Total Carb`,
  total_protin: `Total protein`,
  total_main_meal_count: `Total main meal count`,
  total_salad_count: `Total salad count`,
  total_snack_count: `Total snack count`,
  statistics: `Statistics`,
  clients: `Clients`,
  code: `Code`,
  gender: `Gender`,
  address: `Adddress`,
  active: `Active`,
  un_active: `Un Active`,
  done: "Done",
  loading: `Loading`,
  male: `Male`,
  female: `Female`,
  edit_client: `Edit Client Information`,
  edited_succesfully: `Edited Succesfully`,
  meal_name: `Meal Name`,
  type_is_required: `Type is required`,
  meal_is_required: `Meal is required`,
  quantity_is_required: `Quantity is required`,
  default_carp_is_required: `Default carb is required`,
  default_protin_is_required: `Default protein is required`,
  added:`Added Successfully !`,
  select_date:`Select Date`,
  client_name:`Client Name`,
  main_meal_count:`Main Meal Count`,
  choose_date:`Choose Date`,
  address:`Address`
  
};

export default en;
