import PropTypes from "prop-types";
// @mui
import { List, Stack, Box, Typography } from "@mui/material";

//

import NavList from "./NavList";

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  sx: PropTypes.object,
  data: PropTypes.array,
};

export default function NavSectionVertical({ data, sx, ...other }) {
  return (
    <Stack>
      <Stack height="26px" />
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
           <img
          src="/assets/images/logo/logo.svg"
          alt=""
          style={{
            height: "100px",
            width: "100px",
          }}
        />
      </Box>

   
      {data.map((group, index) => {
        //  const key = group.subheader || group.items[0].title;

        return (
          <List key={index} disablePadding sx={{ px: 2 }}>
            {group.items?.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
