import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET PACKEGES
export const getPackagesService = createAsyncThunk(
  "packages/getPackagesService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.packagesURL, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// POST =>  CREATE PACKAGE
export const createPackageService = createAsyncThunk(
  "packages/createPackageService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.packagesURL, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE PACKAGE
export const deletePackageService = createAsyncThunk(
  "packages/deletePackageService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.packagesURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// GET =>  GET PACKAGE BY ID
export const getPackageByIdService = createAsyncThunk(
  "packages/getPackageByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.packagesURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// UPDATE =>  UPDATE PACKAGE
export const updatePackageService = createAsyncThunk(
  "packages/updatePackageService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.post(
        `${URLS.updatePackagesURL}/${params?.id}`,
        params?.data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
