import { createSlice } from "@reduxjs/toolkit";
import {
  getKitchenService,
  getKitchenStatisticsService,
  getKitchenByMealService,
  getKitchenByClientsService,
} from "../services/kitchen_service";
// ----------------------------------------------------------------------

const initialState = {
  kitchens: [],
  metaData: null,
  isLoading: false,
  statistics: [],
  meals: [],
  clients: [],
  metaClients: null,
  date: null,
};

const slice = createSlice({
  name: "kitchen",
  initialState,
  reducers: {},
  setSelectDate: (state, action) => {
    state.date = action.payload;
  },
  extraReducers: {
    // ==> GET KITCHEN
    [getKitchenService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.kitchens = action.payload?.data?.data?.data;
      state.metaData = action.payload?.data?.data?.meta;
    },
    [getKitchenService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getKitchenService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET Kitchen STATICS
    [getKitchenStatisticsService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.statistics = action.payload.data?.data;
    },
    [getKitchenStatisticsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getKitchenStatisticsService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET Kitchen BY MEAL
    [getKitchenByMealService.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.meals = action.payload.data.data;
    },
    [getKitchenByMealService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getKitchenByMealService.pending]: (state) => {
      state.isLoading = true;
    },

    // GET =>  GET Kitchen BY CLIENTS
    [getKitchenByClientsService.fulfilled]: (state, action) => {
      console.log(action.payload?.data?.data);
      state.isLoading = false;
      state.clients = action.payload.data.data?.data;
      state.metaClients = action.payload?.data?.data?.pagination;
    },
    [getKitchenByClientsService.rejected]: (state, action) => {
      state.isLoading = false;
    },
    [getKitchenByClientsService.pending]: (state) => {
      state.isLoading = true;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setSelectDate } = slice.actions;
