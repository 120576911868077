import PropTypes from "prop-types";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Typography,
  Link,
} from "@mui/material";

// utils
import { bgBlur } from "../../../utils/cssStyles";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// config
import { HEADER, NAV } from "../../../config";
// components
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";
//
import Searchbar from "./Searchbar";
// locales
import { useLocales } from "../../../locales";
import Image from "../../../components/image";
// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const theme = useTheme();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === "horizontal";

  const isNavMini = themeLayout === "mini";

  const isDesktop = useResponsive("up", "lg");

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const { currentLang, onChangeLang } = useLocales();

  let userInfo = JSON.parse(localStorage.getItem("user"));

  const handleChangeLang = (newLang) => {
    onChangeLang(newLang);
  };

  const renderContent = (
    <>
      {isDesktop && isNavHorizontal}

      {!isDesktop && (
        <IconButton onClick={onOpenNav} sx={{ mr: 1, color: "text.primary" }}>
          <Iconify icon="eva:menu-2-fill" />
        </IconButton>
      )}

      <Stack
        flexGrow={1}
        direction="row"
        // spacing={{ xs: 0.5, sm: 1.5 }}
        alignItems="center"
        justifyContent={"flex-end"}
      >
        {!isDesktop ? (
          <>
            <Link
              underline="none"
              sx={{
                color: "#535353",
                fontWeight: 600,

                cursor: "pointer",
              }}
              onClick={() =>
                handleChangeLang(currentLang.value === "en" ? "ar" : "en")
              }
            >
              {currentLang.label}
            </Link>
            <img src="/assets/icons/navbar/setting_ic.svg" alt="arrow" />
          </>
        ) : (
          <>
            <Stack
              flexGrow={1}
              direction="row"
              //  spacing={{ xs: 0.5, sm: 1.5 }}
              alignItems="center"
              // justifyContent={"space-between"}
              // bgcolor={"red"}
            >
             

              <Stack
                flexGrow={1}
                direction="row"
                //  spacing={{ xs: 0.5, sm: 1.5 }}
                alignItems="center"
                justifyContent={"flex-end"}
              >
                <Link
                  underline="none"
                  sx={{
                    color: "#fff",
                    fontWeight: 600,
                    fontSize: "16px",
                    cursor: "pointer",
                    paddingRight: "16px",
                  }}
                  onClick={() =>
                    handleChangeLang(currentLang.value === "en" ? "ar" : "en")
                  }
                >
                  {currentLang.label}
                </Link>

                <Stack mr="10px" />

                <img
                  src="/assets/icons/global.svg"
                  alt=""
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    handleChangeLang(currentLang.value === "en" ? "ar" : "en")
                  }
                />

                {/**        <Avatar src={userInfo?.avatar_url} alt="" /> */}

                <Stack mr="1px" />

                {/**  <Typography
                  sx={{
                    color: "#535353",
                    fontWeight: 400,
                    fontSize: "13px",
                    paddingRight: "3px",
                  }}
                >
                  {userInfo?.full_name}
                </Typography> */}
              </Stack>
            </Stack>
          </>
        )}
      </Stack>
    </>
  );

  return (
    <AppBar
      sx={{
        position: "realtive",
        background: `#01A65A`,
        boxShadow: "0px 4px 4px rgba(202, 199, 199, 0.25)",
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        border: "1px solid rgba(255, 197, 41, 0.1)",

        transition: theme.transitions.create(["height"], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: `calc(100% - ${NAV.W_DASHBOARD - 1}px)`,
          height: HEADER.H_DASHBOARD_DESKTOP,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: "background.default",
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
