import PropTypes from "prop-types";
import { forwardRef } from "react";

// ----------------------------------------------------------------------

const NavItem = forwardRef(
  ({ item, depth, open, active, isExternalLink, ...other }, ref) => {}
);

NavItem.propTypes = {
  open: PropTypes.bool,
  active: PropTypes.bool,
  item: PropTypes.object,
  depth: PropTypes.number,
  isExternalLink: PropTypes.bool,
};

export default NavItem;
