import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET Kitchen
export const getKitchenService = createAsyncThunk(
  "kitchen/getKitchenService",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(URLS.kitchenURL);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET Kitchen STATICS
export const getKitchenStatisticsService = createAsyncThunk(
  "kitchen/getKitchenStatisticsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.kitchenURL}/statistics`, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



// GET =>  GET Kitchen BY MEAL
export const getKitchenByMealService = createAsyncThunk(
  "kitchen/getKitchenByMealService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.kitchenURL}/by-meals`, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



// GET =>  GET Kitchen BY CLIENTS
export const getKitchenByClientsService = createAsyncThunk(
  "kitchen/getKitchenByClientsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.kitchenURL}/by-clients`, {
        params: { ...params },
      });
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);
