import { createAsyncThunk } from "@reduxjs/toolkit";
// urls
import * as URLS from "../../urls";
//axios
import axios from "../../utils/axios";
//_________________________________________________

// GET =>  GET CLIENTS
export const getClientsService = createAsyncThunk(
  "clients/getClientsService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.get(URLS.clientsURL,{params:{...params}});
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// GET =>  GET CLIENT BY ID
export const getClientByIdService = createAsyncThunk(
  "clients/getClientByIdService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.get(`${URLS.clientsURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// EDIT =>  EDIT CLIENT
export const editClientService = createAsyncThunk(
  "clients/editClientService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.clientsURL}/${params?.id}`,
        params?.data
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

// POST =>  CREATE Client
export const createClientService = createAsyncThunk(
  "clients/createClientService",
  async (data, thunkAPI) => {
    try {
      const response = await axios.post(URLS.clientsURL, data);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);

// DELETE =>  DELETE USERS
export const deleteClientService = createAsyncThunk(
  "clients/deleteClientService",
  async (id, thunkAPI) => {
    try {
      const response = await axios.delete(`${URLS.clientsURL}/${id}`);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);



// UPDATE =>  UPDATE CLIENT
export const updateClientService = createAsyncThunk(
  "clients/updateClientService",
  async (params, thunkAPI) => {
    try {
      const response = await axios.put(
        `${URLS.packagesURL}/${params?.id}`,
        params?.data,
       
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
  }
);
